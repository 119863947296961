/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.5;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* end eric meyer's reset */
.clear:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clear {
  display: inline-block;
}
/* hide IE mac \*/
* html .clear {
  height: 1%;
}
.clear {
  display: block;
}
/* */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRsxEYwM7FgeyaSgU71cLG0.woff) format('woff'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hhsxEYwM7FgeyaSgU71cLG0.woff) format('woff'), url(https://fonts.gstatic.com/s/opensans/v13/EInbV5DfGHOiMmvb1Xr-hhampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNShampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
.highlight {
  background: #f0f0f0;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
  font-size: 13px;
  padding: 2px;
}
.highlight .hll {
  background-color: #ffffcc;
}
.highlight .c {
  color: #999988;
  font-style: italic;
}
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}
.highlight .k {
  color: #000000;
  font-weight: bold;
}
.highlight .o {
  color: #000000;
  font-weight: bold;
}
.highlight .cm {
  color: #999988;
  font-style: italic;
}
.highlight .cp {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}
.highlight .c1 {
  color: #999988;
  font-style: italic;
}
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}
.highlight .gd {
  color: #000000;
  background-color: #ffdddd;
}
.highlight .ge {
  color: #000000;
  font-style: italic;
}
.highlight .gr {
  color: #aa0000;
}
.highlight .gh {
  color: #999999;
}
.highlight .gi {
  color: #000000;
  background-color: #ddffdd;
}
.highlight .go {
  color: #888888;
}
.highlight .gp {
  color: #555555;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gu {
  color: #aaaaaa;
}
.highlight .gt {
  color: #aa0000;
}
.highlight .kc {
  color: #000000;
  font-weight: bold;
}
.highlight .kd {
  color: #000000;
  font-weight: bold;
}
.highlight .kn {
  color: #000000;
  font-weight: bold;
}
.highlight .kp {
  color: #000000;
  font-weight: bold;
}
.highlight .kr {
  color: #000000;
  font-weight: bold;
}
.highlight .kt {
  color: #445588;
  font-weight: bold;
}
.highlight .m {
  color: #009999;
}
.highlight .s {
  color: #d01040;
}
.highlight .na {
  color: #008080;
}
.highlight .nb {
  color: #0086B3;
}
.highlight .nc {
  color: #445588;
  font-weight: bold;
}
.highlight .no {
  color: #008080;
}
.highlight .nd {
  color: #3c5d5d;
  font-weight: bold;
}
.highlight .ni {
  color: #800080;
}
.highlight .ne {
  color: #990000;
  font-weight: bold;
}
.highlight .nf {
  color: #990000;
  font-weight: bold;
}
.highlight .nl {
  color: #990000;
  font-weight: bold;
}
.highlight .nn {
  color: #555555;
}
.highlight .nt {
  color: #000080;
}
.highlight .nv {
  color: #008080;
}
.highlight .ow {
  color: #000000;
  font-weight: bold;
}
.highlight .w {
  color: #bbbbbb;
}
.highlight .mf {
  color: #009999;
}
.highlight .mh {
  color: #009999;
}
.highlight .mi {
  color: #009999;
}
.highlight .mo {
  color: #009999;
}
.highlight .sb {
  color: #d01040;
}
.highlight .sc {
  color: #d01040;
}
.highlight .sd {
  color: #d01040;
}
.highlight .s2 {
  color: #d01040;
}
.highlight .se {
  color: #d01040;
}
.highlight .sh {
  color: #d01040;
}
.highlight .si {
  color: #d01040;
}
.highlight .sx {
  color: #d01040;
}
.highlight .sr {
  color: #009926;
}
.highlight .s1 {
  color: #d01040;
}
.highlight .ss {
  color: #990073;
}
.highlight .bp {
  color: #999999;
}
.highlight .vc {
  color: #008080;
}
.highlight .vg {
  color: #008080;
}
.highlight .vi {
  color: #008080;
}
.highlight .il {
  color: #009999;
}
* {
  box-sizing: border-box;
}
html,
body {
  font-size: 62.5%;
}
body {
  background: #fff;
  color: #333;
  font-family: 'Open Sans', 'Gill Sans MT', 'Gill Sans', Corbel, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  overflow-x: hidden;
  text-align: center;
}
article {
  margin: 3rem auto 15rem;
  opacity: 0;
  padding: 0 2rem;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
  animation-delay: 800ms;
}
@media screen and (min-width: 480px) {
  article {
    padding: 0 3rem;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Gill Sans MT', 'Gill Sans', Corbel, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 0.8;
  margin: 0.15em auto 0.3em;
  max-width: 45ch;
}
h2 {
  font-size: 4.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0.6em;
}
@media screen and (min-width: 480px) {
  h2 {
    font-size: 5.2rem;
  }
}
@media screen and (min-width: 640px) {
  h2 {
    font-size: 6.2rem;
  }
}
h3 {
  font-size: 2rem;
  margin-bottom: 1em;
}
a {
  color: #1D2B36;
  transition: color 150ms;
  text-decoration: underline;
}
a:hover,
a:active,
a:focus {
  color: #333;
}
em {
  font-style: italic;
}
strong {
  font-weight: 600;
}
p {
  line-height: 1.6;
  margin: 1rem auto 2rem;
  max-width: 70ch;
}
@media screen and (min-width: 960px) {
  p {
    text-align: justify;
    text-align-last: center;
  }
}
ul {
  list-style: disc outside;
  margin: 0 auto;
  max-width: 45ch;
  padding-left: 2.5rem;
}
img {
  height: auto;
  max-width: 100%;
}
.hidden {
  display: none;
}
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  color: transparent;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
::placeholder {
  color: #333;
  font-size: 1.7rem;
}
form {
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.16);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 74rem;
  margin: 0 auto;
  overflow: hidden;
  padding: 0.5rem 2rem 0;
  position: relative;
  transition: height 400ms;
}
form.removed {
  height: 0;
}
[type="text"],
[type="email"],
textarea {
  appearance: none;
  border: solid 1px #1561f8;
  border-radius: 0.5rem;
  display: block;
  font-family: 'Open Sans', 'Gill Sans MT', 'Gill Sans', Corbel, Arial, sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  width: 100%;
}
[type="text"]:focus,
[type="email"]:focus,
textarea:focus {
  outline: none;
  border-color: #000;
  box-shadow: 0 0 0 3px #1561f8;
}
[type="submit"] {
  appearance: none;
  border: solid 1px #1561f8;
  border-radius: 0.5rem;
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  width: 100%;
  background-color: #1561f8;
  box-shadow: 0 0.25em 0.75em rgba(21, 97, 248, 0.42);
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Open Sans', 'Gill Sans MT', 'Gill Sans', Corbel, Arial, sans-serif;
  font-size: 2.4rem;
  font-weight: 900;
  letter-spacing: 1px;
  padding: 0.583333333em 0;
  position: relative;
  text-transform: uppercase;
  transition: background-color 300ms, bottom 100ms;
}
[type="submit"]:focus {
  box-shadow: 0 0 3px 3px #1D2B36;
  outline: 0;
  top: 1px;
}
[type="submit"]:hover {
  background-color: #064bd4;
}
[type="submit"]:active {
  top: 2px;
}
@media screen and (min-width: 640px) {
  #form-email {
    width: 41%;
  }
}
#form-first_name {
  width: 65%;
}
@media screen and (min-width: 640px) {
  #form-first_name {
    width: 33.5%;
  }
}
#form-zip_code {
  width: 32%;
}
@media screen and (min-width: 640px) {
  #form-zip_code {
    width: 21%;
  }
}
[for="select-country"] {
  cursor: pointer;
  font-size: 1.1rem;
  font-style: italic;
  height: auto;
  margin: 0 0.6rem 0.7rem 0;
  text-align: right;
  width: 100%;
}
[for="select-country"]:hover,
[for="select-country"]:active,
[for="select-country"]:focus {
  text-decoration: underline;
}
[for="select-country"].hidden {
  height: 0;
  margin: 0;
}
select {
  display: none;
  margin: 1.5rem auto;
  max-width: 91%;
}
select.visible {
  display: block;
}
.disclaimer {
  color: #8facc3;
  font-size: 80%;
  margin-top: 1rem;
}
.disclaimer a {
  color: #6e94b1;
  font-weight: 600;
}
.disclaimer br {
  display: none;
}
@media screen and (min-width: 480px) {
  .disclaimer br {
    display: block;
  }
}
.submitted {
  background-color: rgba(255, 255, 255, 0.85);
  bottom: 0;
  content: ' ';
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
}
header {
  align-items: center;
  background-image: url('/images/lock-bg.png');
  background-repeat: no-repeat;
  background-size: auto 50vh;
  background-position: center 5vh;
  padding: 50vh 2rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  width: 100vw;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
}
@media (orientation: landscape) and (max-width: 640px) {
  header {
    background-position: 9vw center;
    background-size: auto 68%;
    justify-content: center;
    padding: 2rem 2rem 2rem 40vw;
  }
}
header h1 {
  font-size: 7.25vh;
  font-variant: small-caps;
  letter-spacing: -2px;
  margin: 2vh auto 3rem;
  text-transform: lowercase;
}
@media (orientation: landscape) and (max-width: 640px) {
  header h1 {
    font-size: 5.4rem;
    margin: 6vw auto 1rem;
  }
}
header p {
  font-size: 2.7rem;
  line-height: 1;
  margin-bottom: 10vh;
  max-width: 20ch;
  text-align: center;
}
@media (orientation: landscape) and (max-width: 640px) {
  header p {
    margin-bottom: 2rem;
  }
}
.arrow-down {
  width: 4rem;
}
.arrow-down .st0 {
  transition: fill 250ms;
}
.arrow-down:hover .st0,
.arrow-down:active .st0,
.arrow-down:focus .st0 {
  fill: #064bd4;
}
#fftf-footer {
  background-color: #222a3c;
  color: #fff;
  margin: 0;
  padding: 4rem 2rem;
  position: absolute;
  left: 0;
  right: 0;
}
#fftf-footer a:link,
#fftf-footer a:visited,
#fftf-footer a:hover,
#fftf-footer a:active,
#fftf-footer a:focus {
  color: #fff;
  font-weight: 400;
}
#fftf-footer a:link,
#fftf-footer a:visited {
  text-decoration: none;
}
#fftf-footer div {
  text-align: left;
}
#fftf-footer strong {
  display: block;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.contact {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 640px) {
  .contact {
    flex-direction: row;
  }
}
.press-contact,
.other-contact {
  margin: 0 auto;
  padding: 2rem;
}
.press-contact a,
.other-contact a {
  display: block;
}
@media screen and (min-width: 640px) {
  .press-contact {
    border-right: solid 2px #2c374f;
    margin-right: 0;
  }
}
@media screen and (min-width: 640px) {
  .other-contact {
    margin-left: 0;
  }
}
.copyrightless {
  border-top: solid 2px #2c374f;
  margin-bottom: 1.2rem;
  padding-top: 2rem;
  width: 100%;
}
.footer-logo {
  display: block;
  margin: 0 auto;
  max-width: 90%;
  width: 43rem;
}
.modal-parent {
  background: rgba(255, 255, 255, 0.7);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  right: 0;
  position: fixed;
  top: 0;
  transition: opacity 400ms ease-out;
  width: 100%;
  z-index: 10;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
}
.overlay {
  bottom: 0;
  left: 0;
  height: 100%;
  padding: 1rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: margin-top 0.4s ease-out;
  vertical-align: middle;
}
.modal-content {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 2rem rgba(51, 51, 51, 0.3);
  color: #333;
  display: inline-block;
  margin: 5rem auto 0;
  max-width: 60rem;
  padding: 2rem;
  position: relative;
  text-align: center;
}
.close-modal {
  background: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 2.5rem;
  opacity: 0.4;
  position: absolute;
  right: 0.5rem;
  text-decoration: none;
  top: 0;
}
ul.share {
  text-align: center;
  /* font-size: .000001px; */
  padding-bottom: 4rem;
  padding-left: 0px;
  z-index: 9001;
  transition: opacity 1s linear;
  opacity: 0;
}
ul.share.visible {
  opacity: 1;
}
ul.share li {
  display: inline-block;
  margin: 10px;
}
ul.share button {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 0px;
  font-size: 0px;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
ul.share button.facebook {
  background: url(../images/facebook.png);
  background-size: 50px 50px;
}
ul.share button.google {
  background: url(../images/google.png);
  background-size: 50px 50px;
}
ul.share button.twitter {
  background: url(../images/twitter.png);
  background-size: 50px 50px;
}
@media screen and (min-width: 840px) {
  ul.share:not(.inline) {
    position: fixed;
    left: 0px;
    top: 50%;
    margin-top: -100px;
  }
  ul.share:not(.inline) li {
    margin: 15px 15px 15px 0px;
    display: block;
  }
}
@keyframes bladeFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.spinner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.spinner .blade {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -4%;
  height: 8%;
  width: 100%;
}
.spinner .blade > div {
  width: 25%;
  margin: 0 0 0 auto;
  height: 100%;
  background: #f80d1b;
  border-radius: 1000px;
  opacity: 1;
  animation-duration: 2s;
  animation-name: bladeFade;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.spinner .blade.d02 {
  transform: rotate(-30deg);
}
.spinner .blade.d02 > div {
  animation-delay: -0.16666667s;
}
.spinner .blade.d03 {
  transform: rotate(-60deg);
}
.spinner .blade.d03 > div {
  animation-delay: -0.33333333s;
}
.spinner .blade.d04 {
  transform: rotate(-90deg);
}
.spinner .blade.d04 > div {
  animation-delay: -0.5s;
}
.spinner .blade.d05 {
  transform: rotate(-120deg);
}
.spinner .blade.d05 > div {
  animation-delay: -0.66666667s;
}
.spinner .blade.d06 {
  transform: rotate(-150deg);
}
.spinner .blade.d06 > div {
  animation-delay: -0.83333333s;
}
.spinner .blade.d07 {
  transform: rotate(-180deg);
}
.spinner .blade.d07 > div {
  animation-delay: -1s;
}
.spinner .blade.d08 {
  transform: rotate(-210deg);
}
.spinner .blade.d08 > div {
  animation-delay: -1.16666667s;
}
.spinner .blade.d09 {
  transform: rotate(-240deg);
}
.spinner .blade.d09 > div {
  animation-delay: -1.33333333s;
}
.spinner .blade.d10 {
  transform: rotate(-270deg);
}
.spinner .blade.d10 > div {
  animation-delay: -1.5s;
}
.spinner .blade.d11 {
  transform: rotate(-300deg);
}
.spinner .blade.d11 > div {
  animation-delay: -1.66666667s;
}
.spinner .blade.d12 {
  transform: rotate(-330deg);
}
.spinner .blade.d12 > div {
  animation-delay: -1.83333333s;
}
.circle-spinner {
  animation-name: loader-spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border: dashed 0.1rem #f80d1b;
  border-radius: 50%;
  height: 2.2rem;
  margin: 2rem auto;
  width: 2.2rem;
}
.circle-spinner.large {
  width: 5rem;
  height: 5rem;
  border-width: 0.4rem;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.copy {
  margin: 0 auto;
  max-width: 72ch;
}
.inline {
  display: inline;
  margin: 0;
  padding: 0;
}
.inline li {
  display: inline;
}
ul.piped li,
ol.piped li {
  margin: 0;
  padding: 0;
}
ul.piped li::before,
ol.piped li::before {
  content: '|\00a0';
  display: inline-block;
}
ul.piped li:first-child::before,
ol.piped li:first-child::before {
  content: '';
  display: none;
}
.share-icon.twitter {
  background-position: 0 0;
  width: 6rem;
  height: 6rem;
  background-image: url('../images/share-icons.png');
  background-repeat: no-repeat;
  background-size: 120px 60px;
}
.share-icon.facebook {
  background-position: -50% 0;
  width: 6rem;
  height: 6rem;
  background-image: url('../images/share-icons.png');
  background-repeat: no-repeat;
  background-size: 120px 60px;
}
/*
* Everything is relative.
*
* Including this component. To resize, overwrite the font-size of
* div.share, button.social somewhere in your code.
*
* *****************
* Don't touch this.
* *****************
*/
div.share,
button.social {
  font-size: 1.4rem;
}
div.share {
  border-radius: 7.142857143em;
  display: inline-block;
  text-align: center;
}
@media screen and (min-width: 480px) {
  div.share {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.1);
  }
}
button.social {
  border: 0;
  border-radius: 7.142857143em;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', 'Gill Sans MT', 'Gill Sans', Corbel, Arial, sans-serif;
  font-weight: bold;
  height: 2.142857143em;
  line-height: 2;
  margin-left: 0.357142857em;
  margin-right: 0.357142857em;
  padding: 0 1em 0 2.857142857em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
button.social::before {
  content: '';
  display: block;
  left: 1em;
  position: absolute;
  top: 0.357142857em;
}
button.social.twitter {
  background: #17a1ce;
}
button.social.twitter::before {
  background-position: 0 0;
  width: 1.42857143em;
  height: 1.42857143em;
  background-image: url('../images/share-icons.png');
  background-repeat: no-repeat;
  background-size: auto 1.42857143em;
}
button.social.facebook {
  background: #3b5a95;
}
@media screen and (min-width: 480px) {
  button.social.facebook {
    margin-bottom: 0;
  }
}
button.social.facebook::before {
  background-position: -50% 0;
  width: 1.42857143em;
  height: 1.42857143em;
  background-image: url('../images/share-icons.png');
  background-repeat: no-repeat;
  background-size: auto 1.42857143em;
  background-position: 100% 0;
}
button.social.donate {
  padding-left: 1em;
  background: #e6b520;
}
.video {
  border-radius: 1rem;
  height: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 3rem;
  position: relative;
}
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.petition p {
  font-size: 1.9rem;
}
.petition-statement br {
  display: none;
}
@media screen and (min-width: 480px) {
  .petition-statement br {
    display: block;
  }
}
.coalition {
  background-color: #F5F5F5;
  padding: 4rem 2rem;
}
@media screen and (min-width: 640px) {
  .coalition {
    padding: 7rem 4rem;
  }
}
.coalition-logos {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 90%;
}
.coalition-logos img {
  padding: 2rem;
}
@media screen and (min-width: 960px) {
  .coalition-logos img {
    margin: 3.5rem;
    padding: 0;
  }
}
.coalition.small .coalition-logos {
  max-width: 1100px;
}
.coalition.small .coalition-logos img {
  max-height: 70px;
  width: auto;
}
@media screen and (min-width: 800px) {
  .participate {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1114px;
  }
}
.asset {
  border: solid #e1e1e1 2px;
  border-radius: 1rem;
  font-size: 1.6rem;
  margin: 3rem auto;
  max-width: 100%;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 42rem;
}
@media screen and (min-width: 480px) {
  .asset {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    justify-content: space-between;
    width: 35rem;
  }
}
@media screen and (min-width: 800px) {
  .asset {
    margin: 1rem 0;
  }
}
@media screen and (min-width: 960px) {
  .asset {
    width: 42rem;
  }
}
.asset p {
  margin-bottom: 1rem;
}
.asset .highlight {
  border-radius: 8px;
  margin-bottom: 2.5rem;
  text-align: center;
}
@media screen and (min-width: 800px) {
  .asset .highlight {
    margin: 0 auto 2.5rem;
  }
}
.asset .highlight pre {
  overflow: scroll;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.text-download-link {
  color: #1561f8;
  display: block;
  font-size: 1.9rem;
  font-variant: small-caps;
  font-weight: 600;
  margin: 1rem 0;
  text-transform: lowercase;
  text-decoration: none;
}
.text-download-link:hover,
.text-download-link:active {
  text-decoration: underline;
}
@media screen and (min-width: 800px) {
  #website-banner {
    width: 100%;
  }
}
@media screen and (min-width: 800px) {
  #banner-thumbnail {
    display: none;
  }
}
#banner-full-size {
  display: none;
}
@media screen and (min-width: 800px) {
  #banner-full-size {
    display: block;
  }
}
@media screen and (min-width: 800px) {
  #profile-picture {
    width: 36%;
  }
}
@media screen and (min-width: 800px) {
  #profile-picture img {
    height: 19rem;
    margin: 0 auto;
    max-width: none;
    width: auto;
  }
}
@media screen and (min-width: 800px) {
  #cover-photo {
    width: 62%;
  }
}
@media screen and (min-width: 960px) {
  #cover-photo img {
    height: 19rem;
    margin: 0 auto;
    max-width: none;
    width: auto;
  }
}
@media screen and (min-width: 800px) {
  #lock-image {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }
}
@media screen and (min-width: 800px) {
  #lock-image figcaption {
    order: 10;
    width: 60%;
  }
}
@media screen and (min-width: 800px) {
  #lock-image .image-download-link {
    order: 0;
    max-width: 14rem;
  }
}
@media screen and (min-width: 800px) {
  #lock-image .text-download-link {
    margin-left: auto;
    margin-top: 0;
    order: 20;
    width: 75%;
  }
}
.oliver {
  margin: 0 auto;
  max-width: 44rem;
}
.participants {
  text-align: left;
}
.participants h2,
.participants p {
  text-align: center;
}
/*# sourceMappingURL=dist/css/core.css.map */